import React, { useCallback, useEffect, useRef } from "react"
import loadable from "@loadable/component"

import spritesheetFile from "../assets/bouncing-ball-spritesheet.png"

const Sketch = loadable(() => import("react-p5"))

let unit = 0

// Disc rotation & strobing values
let slices = 18;

let spriteRatio = 0.25;

export default function InsightsLateral(props) {

    let frames = useRef(props.frames);

    // Disc design
    let dCanvas = useRef({});

    let ballSpritesheet = useRef({});

    let currentFrame = useRef(0);

    const preload = (p) => {
        ballSpritesheet.current = p.loadImage(spritesheetFile)
    };

    const setup = useCallback((p, canvasParentRef) => {
        p.createCanvas(p.windowWidth, p.windowWidth * (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0])).parent(canvasParentRef)
        p.rectMode(p.CENTER)
        p.frameRate(12);

        dCanvas.current = p.createGraphics(p.width, p.height*2).parent(canvasParentRef);
        dCanvas.current.rectMode(p.CENTER);

        ballSpritesheet.current.loadPixels();

        unit = setUnit(p, (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0]))

        p.background(255);

        discDesign(p);

    }, []);

    const draw = useCallback((p, canvasParentRef) => {
        // Only proceed with the draw loop if the sketch is in view
        if(!props.inView) return;

        p.background(255);
      
        p.push();
        p.translate(p.width / 2, 0);
        p.rotate(p.TWO_PI/slices * currentFrame.current);
        p.image(dCanvas.current, -p.width * 0.5, -p.width * 0.5, p.width, p.height*2);
        p.pop();

        // p.fill(0);
        // p.textSize(16)
        // p.text(~~p.getFrameRate(), 8, 16)
        //p.text("Sketch Width: " + p.width, 8, 40)

        currentFrame.current = (currentFrame.current + 1) % slices;
    }, [props.inView]);

    const windowResized = (p) => {
        p.resizeCanvas(p.windowWidth, p.windowWidth * (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0]))

        dCanvas.current.resizeCanvas(p.width, p.height*2)
        discDesign(p);
        unit = setUnit(p, (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0]))
    }

    function discDesign (p) {
        dCanvas.current.clear();
        dCanvas.current.push();
        dCanvas.current.translate(dCanvas.current.width / 2, dCanvas.current.height / 2);
        dCanvas.current.fill(244)
        dCanvas.current.noStroke();
        dCanvas.current.circle(0, 0, dCanvas.current.height - (8 * unit));
        
        // Loop through all the slices/frames of animation
        for (let i = 0; i < slices; i++) {
            dCanvas.current.push();
            dCanvas.current.rotate((p.TWO_PI / slices) * i - (p.TWO_PI / slices)/2);
            dCanvas.current.stroke(255);
            dCanvas.current.strokeWeight(unit / 2);
            dCanvas.current.line(0, 0, 0, p.height);
            dCanvas.current.pop();
        }
        // Loop through all the frames of animation
        for (let i = 0; i < props.frames; i++) {
            dCanvas.current.push();
            dCanvas.current.rotate((p.TWO_PI / props.frames) * i);
            dCanvas.current.image(ballSpritesheet.current.get(i * 270,0,270,1080), 0-p.height/1.75*spriteRatio/2, p.height/3, p.height/1.75*spriteRatio, p.height/1.75);
            dCanvas.current.pop();
        }
        dCanvas.current.pop();
    }

    function setUnit(p, aspectRatio) {
        return aspectRatio >= 1 ? p.height / 100 : p.width / 100;
    }

    return (
        <Sketch preload={preload} setup={setup} draw={draw} windowResized={windowResized}/>
    )
};