import React, { useCallback, useEffect, useRef } from "react"
import loadable from "@loadable/component"

import acParallaxStillFile from "../assets/AC_Parallax_Still.jpg"
import acParallaxBackwardFile from "../assets/AC_Parallax_Backward.jpg"
import acParallaxForwardFile from "../assets/AC_Parallax_Forward.jpg"

const Sketch = loadable(() => import("react-p5"))

// Disc rotation & strobing values
let slices = 18;

export default function InsightsParallax(props) {

    let unit = useRef(0);

    // Disc design
    let dCanvas = useRef({});

    let frames = useRef(18);

    let currentFrame = useRef(0);

    let slipmat = useRef({});

    const preload = (p) => {
        if(props.frames === 17){
            slipmat.current = p.loadImage(acParallaxBackwardFile)
        } else if(props.frames === 19){
            slipmat.current = p.loadImage(acParallaxForwardFile)
        } else {
            slipmat.current = p.loadImage(acParallaxStillFile)
        }
    }

    const setup = useCallback((p, canvasParentRef) => {
        p.createCanvas(p.windowWidth, p.windowWidth * (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0])).parent(canvasParentRef)
        p.rectMode(p.CENTER)
        p.frameRate(12);

        slipmat.current.loadPixels();

        dCanvas.current = p.createGraphics(p.width/2, p.height).parent(canvasParentRef);
        dCanvas.current.rectMode(p.CENTER);

        unit.current = setUnit(p, (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0]))

        frames.current = props.frames;

        p.background(255);
        
        discDesign(p);

    }, []);

    const draw = useCallback((p) => {
        // Only proceed with the draw loop if the sketch is in view
        if(!props.inView) return;

        p.background(255);
      
        // Left Design
        p.push();
        p.translate(p.width / 4, p.height/2);
        p.rotate(p.TWO_PI/slices * currentFrame.current);
        p.image(slipmat.current, -p.height * 0.5 + unit.current, -p.height * 0.5 + unit.current, p.height - unit.current * 2, p.height - unit.current * 2);
        p.pop();
        p.push();

        // Right Design
        p.translate(p.width / 4 * 3, p.height/2);
        p.rotate(p.TWO_PI/slices * currentFrame.current);
        p.image(dCanvas.current, -p.height * 0.5, -p.height * 0.5, p.height, p.height);  
        p.pop();

        currentFrame.current = (currentFrame.current + 1) % slices;
    }, [props.inView]);

    function discDesign(p) {
        dCanvas.current.clear();
        dCanvas.current.push();
        dCanvas.current.translate(dCanvas.current.width / 2, dCanvas.current.height / 2);
        dCanvas.current.fill(244);
        dCanvas.current.noStroke();
        dCanvas.current.circle(0, 0, dCanvas.current.height - unit.current * 2);

        dCanvas.current.stroke(255);
        dCanvas.current.strokeWeight(unit.current * 0.25);
        
        // Draw frame segments/boundaries
        for (let i = 0; i < slices; i++) {
            dCanvas.current.push();
            dCanvas.current.rotate((p.TWO_PI / slices) * i - (p.TWO_PI / slices)/2);
            dCanvas.current.fill(255);
            dCanvas.current.line(0, 0, 0, p.height/2 - unit.current);
            dCanvas.current.pop();
        }   

        dCanvas.current.stroke(0);
        dCanvas.current.strokeWeight(unit.current / 16);

        // Draw animation frames
        for (let i = 0; i < frames.current; i++) {
            dCanvas.current.push();
            dCanvas.current.rotate((p.TWO_PI / frames.current) * i);
            dCanvas.current.fill(255);
            dCanvas.current.translate(0, dCanvas.current.height / 3);
            // dCanvas.current.rotate((p.TWO_PI / frames.current) * i);
            dCanvas.current.rect(0, 0, unit.current*2, unit.current*2);
            dCanvas.current.pop();
        }
        
        dCanvas.current.line(-unit.current * 2, 0, unit.current * 2, 0);
        dCanvas.current.line(0, unit.current * 2, 0, -unit.current * 2);   

        dCanvas.current.pop();
    }

    const windowResized = (p) => {
        p.resizeCanvas(p.windowWidth, p.windowWidth * (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0]))
        dCanvas.current.resizeCanvas(p.width/2, p.height)

        unit.current = setUnit(p, (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0]))
        
        discDesign(p);
    }
    
    function setUnit(p, aspectRatio) {
        return aspectRatio >= 1 ? p.height / 100 : p.width / 100;
    }

    return (
        <Sketch preload={preload} setup={setup} draw={draw} windowResized={windowResized}/>
    )
};