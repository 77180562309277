import React, { useCallback } from "react"
import loadable from "@loadable/component"

const Sketch = loadable(() => import("react-p5"))

let unit = 0

// Disc design
let dCanvas;

// Disc rotation & strobing values
let spinning = false;
let strobing = false;
let slices = 18;
let spinSpeed = 45;
let strobeRate = 13.5;
let discRotation = 0;
let rotationDelta = 0;
let degreesPerSecond = 0; //(360 * spinSpeed) / 60;

let slider
let spinButton
let syncButton
let label

export default function LoopSketchIntro(props) {

    const setup = useCallback((p, canvasParentRef) => {
        p.createCanvas(p.windowWidth, p.windowWidth * (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0])).parent(canvasParentRef)
        p.pixelDensity(2);
        p.rectMode(p.CENTER)
        //p.frameRate(30)

        dCanvas = p.createGraphics(p.width, p.height);
        dCanvas.pixelDensity(2);
        dCanvas.rectMode(p.CENTER);
        dCanvas.textAlign(p.RIGHT);

        label = p.createElement('div', 'Slices: ' + slices).parent(canvasParentRef)
        label.addClass('slider-label')

        slider = p.createSlider(8, 24, 18, 1).parent(canvasParentRef)
        //slider.position(10, 10);
        slider.style('width', '256px');

        spinButton = p.createButton("Spin").parent(canvasParentRef)
        syncButton = p.createButton("Sync").parent(canvasParentRef)

        unit = setUnit(p, props.aspectRatio)

        spinButton.mousePressed(spinToggle)
        syncButton.mousePressed(syncToggle)
    }, []);

    const draw = useCallback((p, canvasParentRef) => {
        // Only proceed with the draw loop if the sketch is in view
        if(p.frameCount && !props.inView) return;

        if(strobing){
            p.background(248)
            p.background('#F1EDEA')
        } else {
            p.background(244)
            p.background('#E9E6E3')
        }
        
        discDesign(p);

        // p.textSize(16)
        // p.text(~~p.getFrameRate(), 8, 16)
        // p.text("Sketch Width: " + p.width, 8, 40)
      
        p.push();
        p.translate(p.width / 2, p.height / 2);
        calculateRotation(p);
        p.rotate(p.radians(discRotation));
        p.image(dCanvas, -p.width * 0.5, -p.height * 0.5, p.width, p.height);
        p.pop();

        slices = slider.value()
        label.html('Slices: ' + slices)

        strobeRate = slices * 45/60

    }, [props.inView]);

    const windowResized = (p) => {
        p.resizeCanvas(p.windowWidth, p.windowWidth * (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0]))

        dCanvas.resizeCanvas(p.width, p.height)
        unit = setUnit(p, props.aspectRatio)
    }

    function spinToggle() {
        spinning = !spinning;
    }

    function syncToggle() {
        strobing = !strobing;
    }

    function discDesign (p) {
        dCanvas.clear();
        dCanvas.push();
        dCanvas.translate(p.width / 2, p.height / 2);
        dCanvas.strokeWeight(unit/6);
        dCanvas.circle(0, 0, p.height - (4 * unit));

        dCanvas.textSize(2*unit);
        dCanvas.textStyle(p.BOLD);
        
        // Loop through all the slices/frames of animation
        for (let i = 0; i < slices; i++) {
            dCanvas.push();
            dCanvas.rotate((p.TWO_PI / slices) * -i);
            dCanvas.translate(0, p.height / 2.75);
            // dCanvas.fill("#E9E6E3");
            dCanvas.fill("#fff");
            
            dCanvas.stroke("#847E79");
            //dCanvas.rotate(((p.PI) / slices) * i);
            dCanvas.rect(0, 0, 7*unit, 7*unit);
            dCanvas.noStroke();
            dCanvas.fill("#847E79");

            //dCanvas.fill("#fff");
            dCanvas.text(i+1, 2.5*unit, 2.5*unit);
            dCanvas.pop();
        }
        dCanvas.pop();
    }

    function calculateRotation(p) {
        degreesPerSecond = spinning
          ? p.lerp(degreesPerSecond, (360 * spinSpeed) / 60, 0.025)
          : p.lerp(degreesPerSecond, 0, 0.025);
      
        if (strobing) {
          if (rotationDelta >= 1000 / strobeRate) {
            discRotation += degreesPerSecond * (1000 / strobeRate) * 0.001;
            rotationDelta = 0;
          }
        } else {
          discRotation += (degreesPerSecond / 1000) * p.deltaTime;
        }
      
        rotationDelta += p.deltaTime;
        discRotation %= 360;
      }

    function setUnit(p, aspectRatio) {
        return aspectRatio >= 1 ? p.height / 100 : p.height / 100;
    }

    return (
        <Sketch setup={setup} draw={draw} windowResized={windowResized} />
    )
};