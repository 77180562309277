import React from "react"
import { createGlobalStyle } from "styled-components"
import "../reset.css"
require(`katex/dist/katex.min.css`)
require("../../static/fonts.css")

const GlobalStyle = createGlobalStyle`
  :root {

    // Fluid type concepts from https://utopia.fyi/type/calculator
    --fluid-min-width: 768;
    --fluid-max-width: 2000;

    --fluid-screen: 100vw;
    --fluid-bp: calc(
      (var(--fluid-screen) - var(--fluid-min-width) / 16 * 1rem) /
        (var(--fluid-max-width) - var(--fluid-min-width))
    );

  }

  @media screen and (max-width: 768px) {
    :root {
      --fluid-screen: calc(var(--fluid-min-width) * 1px);
    }
  }
  
  @media screen and (min-width: 2000px) {
    :root {
      --fluid-screen: calc(var(--fluid-max-width) * 1px);
    }
  }

  :root {
    --f-1-min: 8.00;
    --f-1-max: 14.00;
    --step-1: calc(
      ((var(--f-1-min) / 16) * 1rem) + (var(--f-1-max) - var(--f-1-min)) *
        var(--fluid-bp)
    );

    --f-1-25-min: 10.00;
    --f-1-25-max: 16.00;
    --step-1-25: calc(
      ((var(--f-1-25-min) / 16) * 1rem) + (var(--f-1-25-max) - var(--f-1-25-min)) *
        var(--fluid-bp)
    );

    --f-1-5-min: 12.00;
    --f-1-5-max: 18.00;
    --step-1-5: calc(
      ((var(--f-1-5-min) / 16) * 1rem) + (var(--f-1-5-max) - var(--f-1-5-min)) *
        var(--fluid-bp)
    );

    --f-1-75-min: 14.00;
    --f-1-75-max: 20.00;
    --step-1-75: calc(
      ((var(--f-1-75-min) / 16) * 1rem) + (var(--f-1-75-max) - var(--f-1-75-min)) *
        var(--fluid-bp)
    );

    --f-2-min: 16.00;
    --f-2-max: 22.00;
    --step-2: calc(
      ((var(--f-2-min) / 16) * 1rem) + (var(--f-2-max) - var(--f-2-min)) *
        var(--fluid-bp)
    );

    --f-2-25-min: 18.00;
    --f-2-25-max: 24.00;
    --step-2-25: calc(
      ((var(--f-2-25-min) / 16) * 1rem) + (var(--f-2-25-max) - var(--f-2-25-min)) *
        var(--fluid-bp)
    );

    --f-2-5-min: 20.00;
    --f-2-5-max: 28.00;
    --step-2-5: calc(
      ((var(--f-2-5-min) / 16) * 1rem) + (var(--f-2-5-max) - var(--f-2-5-min)) *
        var(--fluid-bp)
    );

    --f-3-min: 24.00;
    --f-3-max: 32.00;
    --step-3: calc(
      ((var(--f-3-min) / 16) * 1rem) + (var(--f-3-max) - var(--f-3-min)) *
        var(--fluid-bp)
    );

    --f-3-5-min: 28.00;
    --f-3-5-max: 36.00;
    --step-3-5: calc(
      ((var(--f-3-5-min) / 16) * 1rem) + (var(--f-3-5-max) - var(--f-3-5-min)) *
        var(--fluid-bp)
    );

    --f-4-min: 32.00;
    --f-4-max: 40.00;
    --step-4: calc(
      ((var(--f-4-min) / 16) * 1rem) + (var(--f-4-max) - var(--f-4-min)) *
        var(--fluid-bp)
    );

    --f-4-5-min: 36.00;
    --f-4-5-max: 44.00;
    --step-4-5: calc(
      ((var(--f-4-5-min) / 16) * 1rem) + (var(--f-4-5-max) - var(--f-4-5-min)) *
        var(--fluid-bp)
    );

    --f-5-min: 40.00;
    --f-5-max: 56.00;
    --step-5: calc(
      ((var(--f-5-min) / 16) * 1rem) + (var(--f-5-max) - var(--f-5-min)) *
        var(--fluid-bp)
    );

    --f-6-min: 48.00;
    --f-6-max: 96.00;
    --step-6: calc(
      ((var(--f-6-min) / 16) * 1rem) + (var(--f-6-max) - var(--f-6-min)) *
        var(--fluid-bp)
    );

    --f-7-min: 56.00;
    --f-7-max: 64.00;
    --step-7: calc(
      ((var(--f-7-min) / 16) * 1rem) + (var(--f-7-max) - var(--f-7-min)) *
        var(--fluid-bp)
    );

    --f-8-min: 64.00;
    --f-8-max: 72.00;
    --step-8: calc(
      ((var(--f-8-min) / 16) * 1rem) + (var(--f-8-max) - var(--f-8-min)) *
        var(--fluid-bp)
    );

    --f-9-min: 72.00;
    --f-9-max: 80.00;
    --step-9: calc(
      ((var(--f-9-min) / 16) * 1rem) + (var(--f-9-max) - var(--f-9-min)) *
        var(--fluid-bp)
    );

    --f-10-min: 80.00;
    --f-10-max: 88.00;
    --step-10: calc(
      ((var(--f-10-min) / 16) * 1rem) + (var(--f-10-max) - var(--f-10-min)) *
        var(--fluid-bp)
    );

    --f-11-min: 88.00;
    --f-11-max: 96.00;
    --step-11: calc(
      ((var(--f-11-min) / 16) * 1rem) + (var(--f-11-max) - var(--f-11-min)) *
        var(--fluid-bp)
    );

    --f-12-min: 96.00;
    --f-12-max: 104.00;
    --step-12: calc(
      ((var(--f-12-min) / 16) * 1rem) + (var(--f-12-max) - var(--f-12-min)) *
        var(--fluid-bp)
    );


    /* Needs customising */
    --gridColumnCount: 6;
    --projectGridColumnCount: 6;
    --gridColumnGap: calc(12 / 1280 * 100vw);
    --gridMarginGapRelative: calc( 24 / 1280 );
    /* --gridMarginGap: calc(var(--gridMarginGapRelative) * 100vw); */
    --gridMarginGap: 20px;
    --gridTemplateColumnsDefault: repeat( var(--gridColumnCount), minmax(0,1fr) );

    --projectGridTemplateColumnsDefault: repeat( var(--projectGridColumnCount), minmax(0,1fr) );

    @media screen and (min-width: 768px){
      --gridColumnCount: 12;
      --projectGridColumnCount: 10;
    }
  }
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-font-smoothing: antialiased;
    -webkit-font-smoothing: antialias !important;
    -moz-font-smoothing: antialias !important;
    font-smoothing: antialias !important;
  }
  body {
    font-family: "Soehne";
    //font-family: "Inter";
    //font-family: -apple-system, BlinkMacSystemFont, "Inter", "IBM Plex Sans", Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    letter-spacing: -0.018em;
    //font-family: "GT Pressura Trial";
    background-color: #fff;
    //background-color: #f1efed;
    color: #000;
  }

  ::selection { 
    background: #E9E6E3;
  }
`

export default function Layout({ children }) {
  return (
    <React.Fragment>
      <GlobalStyle />
      {children}
    </React.Fragment>
  )
}
