import SketchWrapper from "../../../../src/components/SketchWrapper";
import InsightsFoundations from "../../../../content/posts/making-animal-collectives-animated-slipmat/sketches/InsightsFoundations";
import InsightsFilmstrip from "../../../../content/posts/making-animal-collectives-animated-slipmat/sketches/InsightsFilmstrip";
import InsightsFannedFilmstrip from "../../../../content/posts/making-animal-collectives-animated-slipmat/sketches/InsightsFannedFilmstrip";
import InsightsSimultaneous from "../../../../content/posts/making-animal-collectives-animated-slipmat/sketches/InsightsSimultaneous";
import InsightsLateral from "../../../../content/posts/making-animal-collectives-animated-slipmat/sketches/InsightsLateral";
import InsightsParallax from "../../../../content/posts/making-animal-collectives-animated-slipmat/sketches/InsightsParallax";
import InsightsFinal from "../../../../content/posts/making-animal-collectives-animated-slipmat/sketches/InsightsFinal";
import * as React from 'react';
export default {
  SketchWrapper,
  InsightsFoundations,
  InsightsFilmstrip,
  InsightsFannedFilmstrip,
  InsightsSimultaneous,
  InsightsLateral,
  InsightsParallax,
  InsightsFinal,
  React
};