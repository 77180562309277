import React from "react"
import styled from "styled-components"
import { useInView } from 'react-intersection-observer';

export default function SketchWrapper({ children }) {
  const { ref, inView, entry } = useInView({
    threshold: 0.2,
  });

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { inView: inView });
    }
    return child;
  });

  return (
    <Wrapper ref={ref}>
      {/* <h2>{`Inside viewport: ${inView}`}</h2> */}
      {/* {children} */}
      {childrenWithProps}
    </Wrapper>
  )
}

const Wrapper = styled.div`
    position: relative;

    /* display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    column-gap: var(--gridColumnGap); */

    grid-column-start: 1;
    grid-column-end: -1;

    padding: calc(var(--gridMarginGap) * 2) 0;

    @media screen and (min-width: 768px) {  
      grid-column-start: 2;
      grid-column-end: 12;
    }

    @media screen and (min-width: 1280px) {  
      grid-column-start: 3;
      grid-column-end: 11;
    }

    @media screen and (min-width: 2000px) {  
      grid-column-start: 3;
      grid-column-end: 11;
    }

    & .slider-label {
        padding: var(--gridMarginGap) 0 calc(var(--gridMarginGap) / 4);
        font-size: 16px;
        font-variant-numeric: tabular-nums;
    }

    & > div.react-p5 {

      
      & > canvas {
        height: auto !important;
        border: 1px solid #000;
        //border-radius: 16px;
        overflow: hidden;
        }
    }
`