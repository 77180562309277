import React, { useCallback, useEffect, useRef } from "react"
import loadable from "@loadable/component"
import spritesheetFile from "../assets/bouncing-ball-spritesheet.png"

const Sketch = loadable(() => import("react-p5"))

let unit = 0

let frames = 18;
let frameHeight;
let frameWidth;
let frameGap;
let frameHandle = 4;

let subFrames = 6;

/*
*
* Showing an endless stream of frames in the background while the animation plays in the foreground
*
*/
export default function InsightsFilmstrip(props) {

    let subFrameCount = useRef(0);

    let currentFrame = useRef(0);

    let ballSpritesheet = useRef({});

    const preload = (p) => {
        ballSpritesheet.current = p.loadImage(spritesheetFile)
    }

    const setup = useCallback((p, canvasParentRef) => {
        p.createCanvas(p.windowWidth, p.windowWidth * (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0])).parent(canvasParentRef)
        p.rectMode(p.CENTER)

        p.frameRate(30);

        ballSpritesheet.current.loadPixels();

        unit = setUnit(p, props.aspectRatio)

        setFilmstripDimensions(p);
    }, []);

    const draw = useCallback((p, canvasParentRef) => {
        // Only proceed with the draw loop if the sketch is in view
        if(!props.inView) return;

        p.clear();

        // Background filmstrip
        p.fill(244);
        p.noStroke();

        let subFrameOffset = (frameWidth + frameGap) / subFrames * subFrameCount.current;

        for(let i = 0; i < frameHandle * 2 + 1; i++){
            let index = i - frameHandle;
            let stripFrame = mod(currentFrame.current + index, frames);
            p.rect(p.width/2 + index * (frameWidth + frameGap) - subFrameOffset, p.height/2, frameWidth, frameHeight);
            p.image(ballSpritesheet.current.get(stripFrame * 270,0,270,1080), p.width/2 + index * (frameWidth + frameGap) - frameHeight / 8  - subFrameOffset, 0, frameHeight / 4, frameHeight);
        }

        p.background(255,255,255,159);

        p.fill(255);
        //p.rect(p.width/2, p.height/2, frameWidth + frameGap*2, frameHeight + frameGap*2);
        p.fill(244);
        //p.rect(p.width/2, p.height/2, frameWidth, frameHeight);
        p.image(ballSpritesheet.current.get(currentFrame.current * 270,0,270,1080), p.width/2 - frameHeight / 8, 0, frameHeight / 4, frameHeight);

        subFrameCount.current++;

        if(p.frameCount % subFrames === 0) {
            currentFrame.current = (currentFrame.current + 1) % frames;
            subFrameCount.current = 0;
        }
    }, [props.inView]);

    const windowResized = (p) => {
        p.resizeCanvas(p.windowWidth, p.windowWidth * (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0]))
        unit = setUnit(p, props.aspectRatio)

        setFilmstripDimensions(p);
    }

    // A trick to get proper mod happening in js (especially negatives)
    function mod(n, m) {
        return ((n % m) + m) % m;
    }

    function setUnit(p, aspectRatio) {
        return aspectRatio >= 1 ? p.height / 100 : p.width / 100;
    }

    function setFilmstripDimensions(p) {
        // Filmstrip dimensions
        frameHeight = p.height - 2 * unit;
        frameWidth = frameHeight * 2/3;
        frameGap = unit/2;
    }

    return (
        <Sketch preload={preload} setup={setup} draw={draw} windowResized={windowResized}/>
    )
};