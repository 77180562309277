import React, { useCallback, useEffect, useRef } from "react"
import loadable from "@loadable/component"

import acSlipmatFile from "../assets/AC_Slipmat_Full.jpg"

const Sketch = loadable(() => import("react-p5"))

// Disc rotation & strobing values
let slices = 18;
let acSlipmat;

export default function InsightsFinal(props) {

    let currentFrame = useRef(0);

    const preload = (p) => {
        acSlipmat = p.loadImage(acSlipmatFile)
    }

    const setup = useCallback((p, canvasParentRef) => {
        p.createCanvas(p.windowWidth, p.windowWidth * (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0])).parent(canvasParentRef)
        p.rectMode(p.CENTER)
        p.frameRate(12);

        acSlipmat.loadPixels();
        p.background(255);

    }, []);

    const draw = useCallback((p) => {
        // Only proceed with the draw loop if the sketch is in view
        if(!props.inView) return;

        p.background(255);
      
        p.push();
        p.translate(p.width / 2, p.height/2);
        p.rotate(p.TWO_PI/slices * currentFrame.current);
        p.image(acSlipmat, -p.height * 0.5, -p.height * 0.5, p.height, p.height);
        p.pop();
        currentFrame.current = (currentFrame.current + 1) % slices;
    }, [props.inView]);

    const windowResized = (p) => {
        p.resizeCanvas(p.windowWidth, p.windowWidth * (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0]))
    }

    return (
        <Sketch preload={preload} setup={setup} draw={draw} windowResized={windowResized}/>
    )
};