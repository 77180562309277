import React, { useCallback, useRef } from "react"
import loadable from "@loadable/component"

const Sketch = loadable(() => import("react-p5"))

let unit = 0

// Disc design
//let dCanvas;

// Disc rotation & strobing values
let spinning = true;
let strobing = false;
let slices = 18;
let spinSpeed = 45;
let strobeRate = 13.5;
let discRotation = 0;
let rotationDelta = 0;
let degreesPerSecond = (360 * spinSpeed) / 60;

export default function LoopSketchPastPresentFuture(props) {

    let dCanvas = useRef({});

    const setup = useCallback((p, canvasParentRef) => {
        p.createCanvas(p.windowWidth, p.windowWidth * (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0])).parent(canvasParentRef)
        p.pixelDensity(1)
        p.rectMode(p.CENTER)
        //p.frameRate(30)

        console.log(dCanvas.current)

        dCanvas.current = p.createGraphics(p.width, p.height*2).parent(canvasParentRef);
        dCanvas.current.rectMode(p.CENTER);

        console.log(dCanvas.current)


        unit = setUnit(p, (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0]))
    }, []);

    const draw = useCallback((p, canvasParentRef) => {
        // Only proceed with the draw loop if the sketch is in view
        if(p.frameCount > 1 && !props.inView) return;

        if(strobing){
            p.background(248)
            p.background('#F1EDEA')
        } else {
            p.background(244)
            p.background('#E9E6E3')
        }
        
        discDesign(p);

        // p.textSize(16)
        // p.text(~~p.getFrameRate(), 8, 16)
        // p.text("Sketch Width: " + p.width, 8, 40)
      
        p.push();
        p.translate(p.width / 2, 0);
        calculateRotation(p);
        p.rotate(p.radians(discRotation));
        p.image(dCanvas.current, -p.width * 0.5, -p.width * 0.5, p.width, p.height*2);
        p.pop();

    }, [props.inView]);

    const windowResized = (p) => {
        p.resizeCanvas(p.windowWidth, p.windowWidth * (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0]))

        dCanvas.current.resizeCanvas(p.width, p.height*2)
        unit = setUnit(p, (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0]))
    }

    const keyPressed = (p) => {
        if (p.keyCode === 49) spinning = !spinning;
        if (p.keyCode === 50) strobing = !strobing;
    }

    function discDesign (p) {
        dCanvas.current.clear();
        dCanvas.current.push();
        dCanvas.current.translate(dCanvas.current.width / 2, dCanvas.current.height / 2);
        dCanvas.current.fill('#fff')
        dCanvas.current.strokeWeight(unit/12);
        dCanvas.current.circle(0, 0, dCanvas.current.height - (8 * unit));
        
        // Loop through all the slices/frames of animation
        for (let i = 0; i < slices; i++) {
            dCanvas.current.push();
            dCanvas.current.rotate((p.TWO_PI / slices) * i);
            dCanvas.current.translate(0, dCanvas.current.height / 2.75);
            dCanvas.current.rotate(((p.PI) / slices) * i);
            dCanvas.current.rect(0, 0, 4*unit, 4*unit);
            dCanvas.current.pop();
        }
        dCanvas.current.pop();
    }

    function calculateRotation(p) {
        degreesPerSecond = spinning
          ? p.lerp(degreesPerSecond, (360 * spinSpeed) / 60, 0.025)
          : p.lerp(degreesPerSecond, 0, 0.025);
      
        if (strobing) {
          if (rotationDelta >= 1000 / strobeRate) {
            discRotation += degreesPerSecond * (1000 / strobeRate) * 0.001;
            rotationDelta = 0;
          }
        } else {
          discRotation += (degreesPerSecond / 1000) * p.deltaTime;
        }
      
        rotationDelta += p.deltaTime;
        discRotation %= 360;
      }

    function setUnit(p, aspectRatio) {
        return aspectRatio >= 1 ? p.height / 100 : p.width / 100;
    }

    return (
            <Sketch setup={setup} draw={draw} windowResized={windowResized} keyPressed={keyPressed}/>
    )
};