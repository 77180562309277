import React, { useCallback, useEffect, useRef } from "react"
import loadable from "@loadable/component"
import spritesheetFile from "../assets/bouncing-ball-spritesheet.png"

const Sketch = loadable(() => import("react-p5"))

/*
*   Playing a spritesheet animation in a simple loop sequence
*/
export default function InsightsFoundations(props) {

    let ballSpritesheet = useRef({});
    let currentFrame = useRef(0);

    const preload = (p) => {
        ballSpritesheet.current = p.loadImage(spritesheetFile)
    }

    const setup = useCallback((p, canvasParentRef) => {
        p.createCanvas(p.windowWidth, p.windowWidth * (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0])).parent(canvasParentRef)
        p.rectMode(p.CENTER)
        ballSpritesheet.current.loadPixels();
        p.frameRate(12);
    }, []);

    const draw = useCallback((p) => {
        // Only proceed with the draw loop if the sketch is in view
        if(!props.inView) return;

        p.background(244)
        p.image(ballSpritesheet.current.get(currentFrame.current * 270,0,270,1080), p.width/2-p.height/4/2,0, p.height/4, p.height);
        currentFrame.current = (currentFrame.current + 1) % 18;
    }, [props.inView]);

    const windowResized = (p) => {
        p.resizeCanvas(p.windowWidth, p.windowWidth * (p.windowWidth > 768 ? props.aspectRatio[1]:props.aspectRatio[0]))
    }

    return (
        <Sketch preload={preload} setup={setup} draw={draw} windowResized={windowResized}/>
    )
};